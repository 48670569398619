import toCamelCase from 'utils/strings';
import fieldDefinitions from './field-definitions';

const getters = {
  isAustralia(state) {
    return state.country === 'aus';
  },
  isValid(state) {
    return state.errors.length === 0;
  },
  isFieldValid(state) {
    return field => state.errors.indexOf(field) === -1;
  },
  savingSuccessful(state) {
    return state.saving_status === 'success';
  },
};

Object.values(fieldDefinitions).forEach(group => {
  Object.entries(group).forEach(([fieldName, field]) => {
    if (!field.show) return;
    getters[`show${toCamelCase(fieldName)}`] = state => field.show(state);
  });
});

export default getters;
