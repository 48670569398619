<template>
  <button :class="buttonClasses" :type="type" :name="name" @click="onClick">
    {{ label }}
  </button>
</template>

<script>
const typeValues = ['button', 'submit'];
const styleValues = ['primary', 'accept', 'decline'];

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'button',
      validator: value => typeValues.indexOf(value) !== -1,
    },
    buttonStyle: {
      type: String,
      default: 'primary',
      validator: value => styleValues.indexOf(value) !== -1,
    },
  },
  computed: {
    buttonClasses() {
      return ['c-form-button', `c-form-button--${this.buttonStyle}`];
    },
  },
  methods: {
    onClick(ev) {
      this.$emit('click', ev);
    },
  },
};
</script>
