import { validatePresence, validateEmail } from 'utils/validators';

export default {
  first_name: {
    default: '',
    validate: validatePresence,
  },
  last_name: {
    default: '',
    validate: validatePresence,
  },
  relationship: {
    default: '',
    validate: validatePresence,
  },
  email: {
    default: '',
    validate: validateEmail,
  },
  child_name: {
    default: '',
  },
  restaurant_address_1: {
    default: '',
  },
  restaurant_address_2: {
    default: '',
  },
  restaurant_state: {
    default: '',
  },
  signature: {
    default: '',
    validate: validatePresence,
  },
};
