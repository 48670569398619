<script>
import Inputmask from 'inputmask';
import TextInput from './text-input.vue';

const fieldMaskAus = '049{5,8}';
const fieldMaskNz = '9{7,12}';

export default {
  extends: TextInput,
  props: {
    autocomplete: {
      type: String,
      default: 'off',
    },
  },
  computed: {
    prefix() {
      return this.$store.getters.isAustralia ? '04' : '';
    },
  },
  mounted() {
    Inputmask({
      mask: this.$store.getters.isAustralia ? fieldMaskAus : fieldMaskNz,
      autoUnmask: true,
      onUnMask: this.addPhonePrefix,
    }).mask(this.$refs.inputField);
  },
  methods: {
    addPhonePrefix(maskedValue, unmaskedValue) {
      return `${this.prefix}${unmaskedValue}`;
    },
    onInputChange(ev) {
      this.inputModel = ev.target.value;
      this.$emit('input', this.inputModel);

      if (typeof this.validateInput === 'function') {
        this.validateInput();
      }
    },
  },
};
</script>
