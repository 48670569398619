<template>
  <modal>
    <div class="c-toce">
      <ca-content v-if="caMaintenancePosition"></ca-content>
      <non-ca-content v-else></non-ca-content>

      <div class="c-toce__buttons">
        <form-button
          button-style="decline"
          name="toceConsent"
          label="DECLINE"
          type="submit"
          onclick="confirm('Are you sure?')"
          @click="decline"
        />
        <form-button
          button-style="accept"
          name="toceConsent"
          label="ACCEPT"
          type="submit"
          onclick="confirm('Are you sure?')"
          @click="accept"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '../modal/index.vue';
import FormButton from '../forms/button.vue';
import CaContent from './ca-modal-content.vue';
import NonCaContent from './non-ca-modal-content.vue';

export default {
  components: {
    Modal,
    FormButton,
    CaContent,
    NonCaContent,
  },
  props: {
    caMaintenancePosition: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(['formData']),
  },
  methods: {
    accept() {
      this.$store.commit('acceptToce');

      this.$emit('submit');
    },
    decline() {
      this.$store.commit('declineToce');

      this.$emit('submit');
    },
  },
};
</script>
