<template>
  <div class="o-cheesy">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 150 31"
      xml:space="preserve"
      class="o-cheesy__svg o-cheesy__svg--one"
    >
      <path
        :fill="fill"
        :stroke="fill"
        stroke-width="1px"
        class="o-cheesy__path"
        d="M0,0c21.9,0,22.5,14.3,40.3,14.3C52,14.3,53,9.5,62.9,9.7C82.2,10.2
        ,84.1,30,105.2,30c20,0,19.6-30,44.8-29.9"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#ffbc0d',
    },
  },
};
</script>
