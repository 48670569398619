import Vuex from 'vuex';
import state from './parental-consent/state';
import getters from './parental-consent/getters';
import actions from './parental-consent/actions';
import mutations from './parental-consent/mutations';
import commons from './commons';

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    commons,
  },
});
