export const ACCEPTANCE_TYPES = {
  accepted: {
    value: 'accepted',
    label: 'Accepted',
  },
  declined: {
    value: 'declined',
    label: 'Declined',
  },
};

export default ACCEPTANCE_TYPES;
