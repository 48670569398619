<template>
  <div :class="containerClasses">
    <label class="c-form-input__label" :for="inputId">{{ label }}</label>
    <select
      :id="inputId"
      ref="choicesSelect"
      v-model="inputModel"
      class="c-form-input__input"
      :name="name"
      @change="onInputChange"
    />
  </div>
</template>

<script>
import Choices from 'choices.js';
import Input from './input.vue';

export default {
  extends: Input,
  data() {
    return {
      inputModel: this.model,
    };
  },
  computed: {
    containerClasses() {
      const classes = ['c-form-input'];

      if (!this.$store.getters.isFieldValid(this.name)) {
        classes.push(`${classes[0]}--has-errors`);
      }

      return classes;
    },
  },
  mounted() {
    const { choicesSelect } = this.$refs;
    this.choices = new Choices(choicesSelect, {
      renderChoiceLimit: 100,
      searchResultLimit: 100,
      shouldSort: false,
      fuseOptions: {
        distance: 0,
        threshold: 0,
      },
      classNames: {
        containerOuter: 'choices c-form-input__choices',
        containerInner: 'c-form-input__choices-inner', // replacing choices__inner
      },
    });

    this.choices.setChoices(() =>
      fetch('/api/v1/utils/bsb_options')
        .then(res => res.json())
        .then(json =>
          json.map(val => ({
            value: val,
            label: `${val.substr(0, 3)}-${val.substr(3)}`,
          }))
        )
    );
  },
  beforeDestroy() {
    if (this.choices) {
      this.choices.destroy();
    }
  },
  methods: {
    onInputChange() {
      this.$emit('input', String(this.inputModel).replace('-', ''));

      if (typeof this.validateInput === 'function') {
        this.validateInput();
      }
    },
  },
};
</script>
