import Vue from './utils/vue';
import store from './store/parental-consent';
import ParentalConsentForm from './components/parental-consent/index.vue';

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('.app-parental-consent-form');

  if (!el) return;

  /* eslint-disable-next-line no-new */
  new Vue({
    el,
    store,
    render(h) {
      return h(ParentalConsentForm, {
        props: {
          formDefaults: JSON.parse(el.dataset.formDefaults),
          submitEndpoint: el.dataset.endpoint,
          settings: JSON.parse(window.maccas.settings),
        },
      });
    },
  });
});
