<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    model: {
      type: [String, Number],
      required: true,
    },
    validateInput: {
      type: Function,
      default: null,
    },
  },
  computed: {
    inputId() {
      if (this.id !== null) {
        return this.id;
      }

      const random = Math.random()
        .toString(36)
        .substring(7);
      return `${this.name}_${random}`;
    },
  },
};
</script>
