export default function toCamelCase(string) {
  const capitalized = string[0].toUpperCase() + string.slice(1);
  return capitalized.replace(/_[a-z0-9]/g, match => match[1].toUpperCase());
}

export function nonEmpty(string, fallback = '') {
  const trimmedString = String(string).trim();
  if (string && trimmedString) {
    return trimmedString;
  }

  return fallback;
}
