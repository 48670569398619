<template>
  <div :class="containerClasses" @click="openDatepicker">
    <label class="c-form-input__label" :for="inputId">
      {{ label }}
    </label>
    <input
      :id="inputId"
      v-model="inputModel"
      class="c-form-input__input"
      type="text"
      readonly
      :name="name"
    />
  </div>
</template>

<script>
import MCDatepicker from 'mc-datepicker';
import Input from './input.vue';

export default {
  extends: Input,
  data() {
    return {
      inputModel: this.model,
      picker: null,
    };
  },
  computed: {
    containerClasses() {
      const classes = ['c-form-input'];

      if (!this.$store.getters.isFieldValid(this.name)) {
        classes.push(`${classes[0]}--has-errors`);
      }

      return classes;
    },
    pickerOptions() {
      const options = {
        el: `#${this.inputId}`,
        dateFormat: 'dd mmm yyyy',
        autoClose: true,
        closeOnBlur: true,
        theme: {
          theme_color: '#ffbc0d',
          main_background: '#fff8e6',
          display: {
            foreground: '#db0007',
            background: '#ffbc0d',
          },
          picker: {
            foreground: '#fff8e6',
            background: '#fff8e6',
          },
          picker_header: {
            active: '#db0007',
            inactive: '#dc7e80',
          },
          button: {
            success: {
              foreground: '#ffbc0d',
            },
          },
          date: {
            active: {
              picked: {
                foreground: '#ffbc0d',
                background: '#db0007',
              },
            },
            inactive: {
              picked: {
                foreground: '#ffd86e',
                background: '#dc7e80',
              },
            },
            marcked: {
              foreground: '#ffbc0d',
            },
          },
          weekday: {
            foreground: '#db0007',
          },
          month_year_preview: {
            active: {
              default: {
                foreground: '#db0007',
              },
              picked: {
                foreground: '#db0007',
                background: 'rgba(219, 0, 7,0.2)',
              },
            },
            inactive: {
              default: {
                foreground: 'rgba(219, 0, 7,0.2)',
              },
              picked: {
                foreground: 'rgba(219, 0, 7,0.2)',
                background: 'rgba(219, 0, 7,0.2)',
              },
            },
          },
        },
      };
      if (this.model) {
        options.selectedDate = new Date(this.model);
      }
      return options;
    },
  },
  created() {
    this.picker = MCDatepicker.create(this.pickerOptions);
    this.inputModel = this.picker.getFormatedDate();
    this.picker.onSelect((date, formattedDate) => {
      this.inputModel = formattedDate || '';
      this.onDateChange();
    });
  },
  methods: {
    onDateChange() {
      this.$emit('input', this.inputModel);

      if (typeof this.validateInput === 'function') {
        this.validateInput();
      }
    },
    openDatepicker() {
      this.picker.open();
    },
  },
};
</script>
