/* eslint arrow-body-style: ["error", "always"] */

import {
  validateMaxLength,
  validatePhone,
  validateNumber,
  validatePresence,
} from 'utils/validators';

export default {
  personal: {
    salutation: {
      default: '',
      validate: validatePresence,
    },
    legal_first_name: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
    },
    legal_last_name: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
    },
    legal_middle_name: {
      default: '',
      validate: value => {
        return validateMaxLength(value, 30);
      },
    },
    preferred_name: {
      default: '',
    },
    phone_number: {
      default: '',
      validate: (value, state) => {
        return validatePhone(value, true, state.country);
      },
    },
    birth_date: {
      default: '',
      validate: validatePresence,
    },
    entitlement_to_work: {
      default: '',
      validate: validatePresence,
      country: 'nz',
    },
    passport_number: {
      show: state => {
        /* NZ/Australian Passport */
        return state.formData.personal.entitlement_to_work === 'M51';
      },
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 20);
      },
      country: 'nz',
    },
    passport_issuing_country: {
      show: state => {
        /* NZ/Australian Passport */
        return state.formData.personal.entitlement_to_work === 'M51';
      },
      default: '',
      validate: validatePresence,
      country: 'nz',
    },
    passport_expiration_date: {
      show: state => {
        /* NZ/Australian Passport */
        return state.formData.personal.entitlement_to_work === 'M51';
      },
      default: '',
      validate: validatePresence,
      country: 'nz',
    },
    birth_certificate_number: {
      show: state => {
        /* NZ/Australian Birth Certificate */
        return ['M53'].includes(state.formData.personal.entitlement_to_work);
      },
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 20);
      },
      country: 'nz',
    },
    resident_visa_number: {
      show: state => {
        /* Resident Visa */
        return state.formData.personal.entitlement_to_work === 'M01';
      },
      default: '',
      validate: validatePresence,
      country: 'nz',
    },
    visa_expiration_date: {
      show: state => {
        /* Resident Visa, Open Work Permit, Student Visa,
          Working Holiday Visa, Permit for Working for this Company,
          or Interim Visa */
        return ['M02', 'M03', 'M04', 'M05', 'M06'].includes(
          state.formData.personal.entitlement_to_work
        );
      },
      default: '',
      validate: validatePresence,
      country: 'nz',
    },
    citizenship_number: {
      show: state => {
        /* Citizenship Number' */
        return ['M52'].includes(state.formData.personal.entitlement_to_work);
      },
      default: '',
      validate: value => {
        return validatePresence(value);
      },
      country: 'nz',
    },
    gender: {
      default: '',
      validate: validatePresence,
    },
    ethnicity: {
      default: '',
      validate: validatePresence,
      country: 'aus',
    },
    student_status: {
      default: '',
      validate: validatePresence,
      country: 'aus',
    },
  },
  address: {
    address_street_1: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
    },
    address_suburb: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
    },
    address_city: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
      country: 'nz',
    },
    address_postcode: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 9);
      },
    },
    address_state: {
      default: '',
      validate: validatePresence,
      country: 'aus',
    },
  },
  emergency: {
    emergency_contact_first_name: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
    },
    emergency_contact_last_name: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
    },
    emergency_contact_relationship: {
      default: '',
      validate: validatePresence,
    },
    emergency_contact_phone_number: {
      default: '',
      validate: (value, state) => {
        return validatePhone(value, true, state.country);
      },
    },
    emergency_contact_address: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 30);
      },
    },
    emergency_contact_city: {
      default: '',
      validate: validatePresence,
    },
    emergency_contact_state: {
      default: '',
      validate: validatePresence,
      country: 'aus',
    },
  },
  bank_account: {
    bank_account_name: {
      default: '',
      validate: value => {
        return validatePresence(value) && validateMaxLength(value, 32);
      },
    },
    bank_account_bsb_number: {
      default: '',
      validate: value => {
        return validateNumber(value, { min: 6, max: 6 }, true);
      },
      country: 'aus',
    },
    bank_account_number: {
      default: '',
      validate: value => {
        return validateNumber(value, { min: 6, max: 16 }, true);
      },
    },
    tax_code: {
      default: '',
      validate: validatePresence,
      country: 'nz',
    },
    tax_number: {
      default: '',
      validate: value => {
        return validateNumber(value, { min: 9, max: 9 }, true);
      },
      country: 'nz',
    },
  },
  uniform: {
    uniform_size_shirt: {
      default: '',
      validate: validatePresence,
    },
    uniform_size_pants: {
      default: '',
      validate: validatePresence,
    },
  },
  other: {
    terms_of_casual_employment_status: {
      default: null,
      counter_excluded: true,
    },
    type_in_name: {
      default: '',
      validate: validatePresence,
      country: 'nz',
    },
    form_completion_date: {
      default: '',
      validate: validatePresence,
    },
  },
};
