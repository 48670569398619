import state from './commons/state';
import getters from './commons/getters';
import actions from './commons/actions';
import mutations from './commons/mutations';

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
