<template>
  <div :class="wrapperClasses.join(' ')">
    <div class="o-wrapper">
      <div class="c-page__header">
        <div class="c-page__logo">
          <img
            class="c-page__logo-arches"
            :src="require('images/golden-arches.svg')"
            alt="Golden Arches"
          />
        </div>
        <div class="c-page__title">
          <h1>Parental Consent Form</h1>
        </div>
      </div>

      <div class="c-page__content">
        <div v-if="formFinished">
          Thank you for submitting the form.
        </div>
        <form v-else class="c-form" @submit.prevent>
          <div v-if="!isValid()" class="c-form__row c-form__row--has-errors">
            Some of the field values are invalid and must be corrected.
          </div>
          <div class="c-form__row">
            <text-input
              label="Parent/Guardian First Name"
              name="first_name"
              :validate-input="validateFirstName"
              :model="formData.first_name"
              @input="updateFirstName"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Parent/Guardian Last Name"
              name="last_name"
              :validate-input="validateLastName"
              :model="formData.last_name"
              @input="updateLastName"
            />
          </div>
          <div class="c-form__row">
            <dropdown
              :options="relationshipOptions"
              label="Relationship"
              name="relationship"
              :validate-input="validateRelationship"
              :model="formData.relationship"
              @input="updateRelationship"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Parent/Guardian Email Address"
              name="email"
              type="email"
              :validate-input="validateEmail"
              :model="formData.email"
              @input="updateEmail"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Child/Dependent Full Name"
              name="child_name"
              :model="formData.child_name"
              :disabled="true"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Restaurant Address 1"
              name="restaurant_address_1"
              :model="formData.restaurant_address_1"
              :disabled="true"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Restaurant Address 2"
              name="restaurant_address_2"
              :model="formData.restaurant_address_2"
              :disabled="true"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Restaurant State"
              name="restaurant_state"
              :model="formData.restaurant_state"
              :disabled="true"
            />
          </div>
          <div class="c-form__row">
            <paragraph>
              <p v-text="consentStatement"></p>
            </paragraph>
          </div>
          <div class="c-form__row">
            <paragraph>
              <p v-text="acknowledgmentStatement"></p>
            </paragraph>
          </div>
          <div class="c-form__row">
            <text-input
              label="Parent/Guardian Signature (enter name)"
              name="signature"
              :validate-input="validateSignature"
              :model="formData.signature"
              @input="updateSignature"
            />
          </div>
          <div class="c-form__row c-form__row--buttons">
            <form-button
              button-style="decline"
              name="consent"
              label="DECLINE CONSENT"
              type="submit"
              value="declined"
              onclick="confirm('Are you sure?')"
              @click="declineConsent"
            />
            <form-button
              button-style="accept"
              name="consent"
              label="PROVIDE CONSENT"
              type="submit"
              value="granted"
              onclick="confirm('Are you sure?')"
              @click="grantConsent"
            />
          </div>
        </form>
      </div>

      <div class="c-page__footer"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { nonEmpty } from 'utils/strings';
import TextInput from '../forms/text-input.vue';
import Dropdown from '../forms/dropdown.vue';
import Paragraph from '../forms/paragraph.vue';
import FormButton from '../forms/button.vue';
// import Datepicker from '../forms/datepicker.vue';

export default {
  components: {
    TextInput,
    Dropdown,
    Paragraph,
    FormButton,
    // Datepicker,
  },
  props: {
    formDefaults: {
      type: Object,
      default: () => {},
    },
    submitEndpoint: {
      type: String,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      relationshipOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.parent_relationship.values),
      ],
    };
  },
  computed: {
    ...mapState(['valid', 'formData', 'savingStatus']),
    consentStatement() {
      const name = this.formData.child_name || 'Applicant';
      return (
        `As ${name} is under 16 years old we require your consent to proceed ` +
        'with their application, and future employment should they accept a position.'
      );
    },
    acknowledgmentStatement() {
      const childName = this.formData.child_name || 'Applicant';
      const relationship = this.relationshipLabel(this.formData.relationship);
      return `I, as ${childName}'s ${relationship},  warrant the truthfulness of the information provided in this form.`;
    },
    wrapperClasses() {
      const classes = ['c-page'];
      if (this.formFinished) {
        classes.push('c-page--centered');
      }
      return classes;
    },
    formFinished() {
      return this.savingStatus === 'success' && this.isValid();
    },
  },
  created() {
    const token = document
      .querySelector('meta[name=csrf-token]')
      .getAttribute('content');
    this.$store.commit('setCsrfToken', token);
    this.$store.commit(
      'updateRestaurantAddress1',
      nonEmpty(this.formDefaults.restaurant_address_1)
    );
    this.$store.commit(
      'updateRestaurantAddress2',
      nonEmpty(this.formDefaults.restaurant_address_2)
    );
    this.$store.commit(
      'updateRestaurantState',
      nonEmpty(this.formDefaults.restaurant_state)
    );
    this.$store.commit(
      'updateChildName',
      nonEmpty(this.formDefaults.child_name)
    );
    this.$store.commit('updateEmail', nonEmpty(this.formDefaults.email));
  },
  methods: {
    ...mapMutations([
      'updateFirstName',
      'updateLastName',
      'updateRelationship',
      'updateEmail',
      'updateSignature',
    ]),
    ...mapActions([
      'validateFirstName',
      'validateLastName',
      'validateRelationship',
      'validateEmail',
      'validateSignature',
    ]),
    ...mapGetters({
      isValid: 'isValid',
      consentSavedSuccessfully: 'savingSuccessful',
    }),
    relationshipLabel(key) {
      const relationship = this.relationshipOptions.find(
        r => !r.disabled && r.value === key
      );

      if (relationship) {
        return relationship.label;
      }

      return '<< Parent / Guardian >>';
    },
    submit() {
      this.$store.dispatch('submit', { endpoint: this.submitEndpoint });
    },
    grantConsent() {
      this.$store.commit('consentGranted');

      this.submit();
    },
    declineConsent() {
      this.$store.commit('consentDeclined');

      this.submit();
    },
  },
};
</script>
