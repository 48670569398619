import fieldDefinitions from './field-definitions';

const formData = {
  consent: '',
};

Object.keys(fieldDefinitions).forEach(fieldName => {
  formData[fieldName] = fieldDefinitions[fieldName].default;
});

export default {
  errors: [],
  csrfToken: '',
  savingStatus: null,
  formData,
};
