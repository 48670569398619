/* eslint-disable no-param-reassign */

import toCamelCase from 'utils/strings';
import { ACCEPTANCE_TYPES } from './constants';
import fieldDefinitions from './field-definitions';

const mutations = {
  accept(state) {
    state.formData.other.personal_details_status =
      ACCEPTANCE_TYPES.accepted.value;
  },
  decline(state) {
    state.formData.other.personal_details_status =
      ACCEPTANCE_TYPES.declined.value;
  },
  acceptToce(state) {
    state.formData.other.terms_of_casual_employment_status =
      ACCEPTANCE_TYPES.accepted.value;
  },
  declineToce(state) {
    state.formData.other.terms_of_casual_employment_status =
      ACCEPTANCE_TYPES.declined.value;
  },
  formInvalid(state, field) {
    state.valid = false;
    if (state.errors.indexOf(field) === -1) {
      state.errors.push(field);
    }
  },
  clearError(state, field) {
    state.errors = state.errors.filter(value => value !== field);
  },
  setCsrfToken(state, token) {
    state.csrfToken = token;
  },
  setCountry(state, country) {
    state.country = country;
  },
  savingComplete(state, status) {
    state.savingStatus = status;
  },
};

// adds mutations for defined fields
Object.entries(fieldDefinitions).forEach(([label, group]) => {
  Object.keys(group).forEach(fieldName => {
    mutations[`update${toCamelCase(fieldName)}`] = (state, value) => {
      state.formData[label][fieldName] = value;
      state.formMeta[label].complete = Object.values(
        state.formData[label]
      ).filter(Boolean).length;
    };
  });
});

export default mutations;
