<template>
  <div :class="containerClasses">
    <label class="c-form-input__label" :for="inputId">
      {{ label }}
    </label>
    <div v-if="helptext" class="c-form-input__helptext">{{ helptext }}</div>
    <div class="c-form-input__wrapper">
      <input
        :id="inputId"
        ref="inputField"
        v-model="inputModel"
        class="c-form-input__input"
        :name="name"
        :type="type"
        :autofocus="autofocus"
        :disabled="disabled"
        @input="onInputChange"
      />

      <cheesy-one v-if="decoration === 'cheesy-one'"></cheesy-one>
      <cheesy-two v-if="decoration === 'cheesy-two'"></cheesy-two>
    </div>
  </div>
</template>

<script>
import Input from './input.vue';
import CheesyOne from './cheesy-one.vue';
import CheesyTwo from './cheesy-two.vue';

const typeValues = ['text', 'email', 'date'];

export default {
  components: {
    CheesyOne,
    CheesyTwo,
  },
  extends: Input,
  props: {
    type: {
      type: String,
      validator: value => typeValues.indexOf(value) !== -1,
      default: 'text',
    },
    autofocus: Boolean,
    decoration: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    helptext: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputModel: this.model,
    };
  },
  computed: {
    containerClasses() {
      const classes = ['c-form-input'];

      if (this.disabled) {
        classes.push(`${classes[0]}--disabled`);
      }
      if (!this.$store.getters.isFieldValid(this.name)) {
        classes.push(`${classes[0]}--has-errors`);
      }

      return classes;
    },
  },
  methods: {
    onInputChange() {
      this.$emit('input', this.inputModel);

      if (typeof this.validateInput === 'function') {
        this.validateInput();
      }
    },
  },
};
</script>
