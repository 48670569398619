<template>
  <div class="c-toce__content">
    <h1>McDonald’s Australia Terms of Casual Employment</h1>

    <p>
      In conjunction with the
      <i>Miscellaneous Award 2020</i> (<strong>Award</strong>) below are the
      terms and conditions of your employment:
    </p>

    <h3>
      Position
    </h3>
    <p>
      You are employed on a casual basis in the position of General Maintenance.
      As a casual employee, you will be paid a casual loading of 25% in addition
      to your base rate of pay.
    </p>

    <h3>
      Probationary Period
    </h3>
    <p>
      The first six months of your employment are on a probationary period. We
      will provide you with the training and coaching to fulfil the role of a
      General Maintenance employee. However, should you not demonstrate the
      required competencies, behaviours and performance standards during this
      period, your employment may be terminated.
    </p>

    <h3>
      Duties
    </h3>
    <p>
      You agree to:
    </p>
    <ol>
      <li>
        comply with all reasonable and lawful directions and instructions given
        by McDonald’s, which may include additional duties in line with your
        skills and competence;
      </li>
      <li>
        act in good faith, use your best endeavours to positively promote
        McDonald’s goodwill and reputation; and
      </li>
      <li>
        comply with McDonald’s policies as varied from time to time. These
        policies are located on Metime and are not intended to form part of your
        contract of employment.
      </li>
    </ol>

    <h3>Remuneration</h3>
    <p>
      You can find your rate of pay in the Award which is located on Metime.
    </p>
    <p>
      As a casual employee, you will be paid a casual loading of 25%. You agree
      that you receive this casual loading as compensation for the fact that you
      are not entitled to the benefits and other attributes of full time or part
      time employment including but not limited to annual leave,
      personal/carer’s leave, notice of termination or redundancy benefits.
    </p>
    <p>
      Your wages will be paid weekly into your nominated bank account.
    </p>
    <p>
      If you are overpaid at any time during your employment with McDonald’s,
      you agree to repay McDonald’s the full amount of any such overpayment. If
      this happens, McDonald’s will discuss repayment options with you.
    </p>

    <h3>Superannuation</h3>
    <p>
      Should you be entitled to superannuation, McDonald’s will make
      superannuation contributions on your behalf as required by the relevant
      superannuation legislation. You can pick your own superannuation fund. If
      you do not pick a superannuation fund, it will be paid into McDonald’s
      nominated fund on your behalf.
    </p>

    <h3>Leave</h3>
    <p>
      In accordance with applicable law, you are entitled to the following forms
      of leave: personal/carer’s leave, parental leave, compassionate leave,
      family and domestic violence leave and long service leave. With the
      exception of long service leave, these periods of leave will be unpaid.
    </p>

    <h3>Workplace Safety</h3>
    <p>
      It is a condition of your employment that you comply with your obligations
      under any work, health and safety laws that apply to your employment,
      particularly those that require you to take care of your own health and
      safety and the health and safety of your fellow workers.
    </p>

    <h3>Workplace Surveillance</h3>
    <p>
      McDonald’s notifies you that continuous ongoing camera, cash register and
      tracking surveillance is carried out in the restaurant in which you work.
      In areas where camera surveillance occurs, notices reminding you of such
      surveillance are installed. You consent to such surveillance from the date
      you commence employment.
    </p>

    <h3>Termination of Employment</h3>
    <p>
      Your employment may be terminated at any time in accordance with the Award
      and the National Employment Standards under the <i>Fair Work Act 2009</i>.
    </p>

    <h3>Acknowledgement</h3>
    <p>
      By clicking on the "Accept" button below, you are providing "written"
      acceptance of McDonald's Australia's Terms of Casual Employment. Should
      you wish to not accept McDonald's Australia's Terms of Casual Employment,
      please click on the "Decline" button below.
    </p>
  </div>
</template>

<script>
export default {};
</script>
