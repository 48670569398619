import 'whatwg-fetch';

export default {
  async submit(endpoint, token, formData) {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
      body: JSON.stringify({ personal_details: formData }),
    });

    return response.json();
  },
};
