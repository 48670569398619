<template>
  <div :class="containerClasses">
    <label class="c-form-input__label" :for="inputId">
      {{ label }}
    </label>
    <div class="c-form-input__wrapper">
      <select
        :id="inputId"
        v-model="selectionModel"
        class="c-form-input__select"
        :name="name"
        @change="onSelectionChange"
      >
        <option
          v-for="({ value, label, disabled }, index) in options"
          :key="index"
          :value="value"
          :disabled="disabled"
        >
          {{ label }}
        </option>
      </select>
      <div class="c-form-input__select-arrow">&#9662;</div>
    </div>
  </div>
</template>

<script>
import Input from './input.vue';

export default {
  extends: Input,
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedValue: this.model,
    };
  },
  computed: {
    selectionModel: {
      get() {
        if (this.selectedValue) {
          return this.selectedValue;
        }
        if (this.options.length && this.options[0].value) {
          return this.options[0].value;
        }
        return '';
      },
      set(value) {
        this.selectedValue = value;
      },
    },
    containerClasses() {
      const classes = ['c-form-input'];

      if (!this.$store.getters.isFieldValid(this.name)) {
        classes.push(`${classes[0]}--has-errors`);
      }

      return classes;
    },
  },
  created() {
    this.$emit('input', this.selectionModel);
  },
  methods: {
    onSelectionChange() {
      this.$emit('input', this.selectedValue);

      if (typeof this.validateInput === 'function') {
        this.validateInput();
      }
    },
  },
};
</script>
