import Vuex from 'vuex';
import state from './personal-details/state';
import getters from './personal-details/getters';
import actions from './personal-details/actions';
import mutations from './personal-details/mutations';
import commons from './commons';

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    commons,
  },
});
