<template>
  <div class="o-cheesy">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 200 21"
      xml:space="preserve"
      class="o-cheesy__svg o-cheesy__svg--two"
    >
      <path
        :fill="fill"
        :stroke="fill"
        stroke-width="1px"
        class="o-cheesy__path"
        d="M200, 0 c-21.6, 0-23.9, 11.6-37, 11.4c-9.6-0.1-16-10.6-37.3-10.5C98.5,
        1, 88.1, 20, 67.6, 20 C47.4, 20, 42, 0, 0, 0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#ffbc0d',
    },
  },
};
</script>
