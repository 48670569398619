<template>
  <div :class="wrapperClasses.join(' ')">
    <div class="o-wrapper">
      <div class="c-page__header">
        <div class="c-page__logo">
          <img
            class="c-page__logo-arches"
            :src="require('images/golden-arches.svg')"
            alt="Golden Arches"
          />
        </div>
        <div class="c-page__title">
          <h1 class="c-page__title-primary">Personal Details Form</h1>
        </div>
      </div>

      <div class="c-page__content">
        <div v-if="formFinished">
          <div v-if="isAustralia">
            Thank you for submitting the form, we will be in contact soon to
            update you on your job at Maccas!
          </div>

          <div v-else>
            Thank you for submitting the form. You may now close this tab.
          </div>
        </div>
        <form v-else class="c-form" action="#" @submit.prevent>
          <form-group
            label="Personal"
            :complete="formMeta.personal.complete"
            :total="formMeta.personal.total"
          />

          <div class="c-form__row">
            <dropdown
              :options="salutationOptions"
              label="Title"
              name="salutation"
              :validate-input="validateSalutation"
              :model="formData.personal.salutation"
              @input="updateSalutation"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Legal First Name"
              name="legal_first_name"
              :validate-input="validateLegalFirstName"
              :model="formData.personal.legal_first_name"
              :autofocus="true"
              helptext="(Please note - Maximum 30 characters)"
              @input="updateLegalFirstName"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Legal Last Name"
              name="legal_last_name"
              :validate-input="validateLegalLastName"
              :model="formData.personal.legal_last_name"
              decoration="cheesy-one"
              helptext="(Please note - Maximum 30 characters)"
              @input="updateLegalLastName"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Legal Middle Name"
              name="legal_middle_name"
              :validate-input="validateLegalMiddleName"
              :model="formData.personal.legal_middle_name"
              decoration="cheesy-two"
              helptext="(Please note - Maximum 30 characters)"
              @input="updateLegalMiddleName"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Preferred Name"
              name="preferred_name"
              :model="formData.personal.preferred_name"
              decoration="cheesy-two"
              helptext="(Only complete if other than above)"
              @input="updatePreferredName"
            />
          </div>
          <div class="c-form__row">
            <phone-input
              label="Phone Number"
              name="phone_number"
              :validate-input="validatePhoneNumber"
              :model="formData.personal.phone_number"
              @input="updatePhoneNumber"
            />
          </div>
          <div class="c-form__row">
            <datepicker
              label="Date of Birth"
              name="birth_date"
              :validate-input="validateBirthDate"
              :model="formData.personal.birth_date"
              @input="updateBirthDate"
            />
          </div>
          <template v-if="!isAustralia">
            <div class="c-form__row">
              <dropdown
                :options="entitlementToWorkOptions"
                label="Entitlement to Work in New Zealand"
                name="entitlement_to_work"
                :validate-input="validateEntitlementToWork"
                :model="formData.personal.entitlement_to_work"
                @input="updateEntitlementToWork"
              />
            </div>
            <div
              v-if="showPassportNumber"
              key="passport_number"
              class="c-form__row"
            >
              <text-input
                label="Passport Number"
                name="passport_number"
                :validate-input="validatePassportNumber"
                :model="formData.personal.passport_number"
                @input="updatePassportNumber"
              />
            </div>
            <div
              v-if="showPassportIssuingCountry"
              key="passport_issuing_country"
              class="c-form__row"
            >
              <dropdown
                :options="passportIssuingCountryOptions"
                label="Passport Country of Issue"
                name="passport_issuing_country"
                :validate-input="validatePassportIssuingCountry"
                :model="formData.personal.passport_issuing_country"
                @input="updatePassportIssuingCountry"
              />
            </div>
            <div
              v-if="showPassportExpirationDate"
              key="passport_expiration_date"
              class="c-form__row"
            >
              <datepicker
                label="Passport Expiration Date"
                name="passport_expiration_date"
                :validate-input="validatePassportExpirationDate"
                :model="formData.personal.passport_expiration_date"
                @input="updatePassportExpirationDate"
              />
            </div>
            <div
              v-if="showBirthCertificateNumber"
              key="birth_certificate_number"
              class="c-form__row"
            >
              <text-input
                label="Birth Certificate Number"
                name="birth_certificate_number"
                :validate-input="validateBirthCertificateNumber"
                :model="formData.personal.birth_certificate_number"
                @input="updateBirthCertificateNumber"
              />
            </div>
            <div
              v-if="showResidentVisaNumber"
              key="resident_visa_number"
              class="c-form__row"
            >
              <masked-input
                label="Resident Visa Number"
                name="resident_visa_number"
                mask="9{0,}"
                :validate-input="validateResidentVisaNumber"
                :model="formData.personal.resident_visa_number"
                @input="updateResidentVisaNumber"
              />
            </div>
            <div
              v-if="showVisaExpirationDate"
              key="visa_expiration_date"
              class="c-form__row"
            >
              <datepicker
                label="Visa Expiration Date"
                name="visa_expiration_date"
                :validate-input="validateVisaExpirationDate"
                :model="formData.personal.visa_expiration_date"
                @input="updateVisaExpirationDate"
              />
            </div>
            <div
              v-if="showCitizenshipNumber"
              key="citizenship_number"
              class="c-form__row"
            >
              <text-input
                label="Citizenship Number"
                name="citizenship_number"
                :validate-input="validateCitizenshipNumber"
                :model="formData.personal.citizenship_number"
                @input="updateCitizenshipNumber"
              />
            </div>
          </template>
          <div v-if="isAustralia" class="c-form__row">
            <dropdown
              :options="studentStatusOptions"
              label="Student Status"
              name="student_status"
              :validate-input="validateStudentStatus"
              :model="formData.personal.student_status"
              @input="updateStudentStatus"
            />
          </div>
          <div class="c-form__row">
            <paragraph>
              <p>
                This information is being collected for reporting purposes only.
              </p>
            </paragraph>
          </div>
          <div class="c-form__row">
            <dropdown
              :options="genderOptionsNormalized"
              label="Gender"
              name="gender"
              :validate-input="validateGender"
              :model="formData.personal.gender"
              @input="updateGender"
            />
          </div>
          <div class="c-form__row">
            <dropdown
              :options="ethnicityOptions"
              label="Ethnicity"
              name="ethnicity"
              :validate-input="validateEthnicity"
              :model="formData.personal.ethnicity"
              @input="updateEthnicity"
            />
          </div>

          <form-group
            label="Address"
            :complete="formMeta.address.complete"
            :total="formMeta.address.total"
          />

          <div class="c-form__row">
            <text-input
              label="Street Address (line 1)"
              name="address_street_1"
              :validate-input="validateAddressStreet1"
              :model="formData.address.address_street_1"
              helptext="(Please note - Maximum 30 characters)"
              @input="updateAddressStreet1"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Suburb"
              name="address_suburb"
              :validate-input="validateAddressSuburb"
              :model="formData.address.address_suburb"
              @input="updateAddressSuburb"
            />
          </div>
          <div v-if="!isAustralia" class="c-form__row">
            <text-input
              label="City/Town"
              name="address_city"
              :validate-input="validateAddressCity"
              :model="formData.address.address_city"
              @input="updateAddressCity"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Post Code"
              name="address_postcode"
              :validate-input="validateAddressPostcode"
              :model="formData.address.address_postcode"
              @input="updateAddressPostcode"
            />
          </div>
          <div v-if="isAustralia" class="c-form__row">
            <dropdown
              :options="stateCodeOptions"
              label="State"
              name="address_state"
              :validate-input="validateAddressState"
              :model="formData.address.address_state"
              @input="updateAddressState"
            />
          </div>

          <form-group
            label="Emergency contact"
            :complete="formMeta.emergency.complete"
            :total="formMeta.emergency.total"
          />

          <div class="c-form__row">
            <text-input
              label="Emergency Contact First Name"
              name="emergency_contact_first_name"
              :validate-input="validateEmergencyContactFirstName"
              :model="formData.emergency.emergency_contact_first_name"
              helptext="(Please note - Maximum 30 characters)"
              @input="updateEmergencyContactFirstName"
            />
          </div>

          <div class="c-form__row">
            <text-input
              label="Emergency Contact Last Name"
              name="emergency_contact_last_name"
              :validate-input="validateEmergencyContactLastName"
              :model="formData.emergency.emergency_contact_last_name"
              helptext="(Please note - Maximum 30 characters)"
              @input="updateEmergencyContactLastName"
            />
          </div>
          <div class="c-form__row">
            <dropdown
              :options="relationshipOptions"
              label="Relationship"
              name="emergency_contact_relationship"
              :validate-input="validateEmergencyContactRelationship"
              :model="formData.emergency.emergency_contact_relationship"
              @input="updateEmergencyContactRelationship"
            />
          </div>
          <div class="c-form__row">
            <phone-input
              label="Primary Phone Number"
              name="emergency_contact_phone_number"
              :validate-input="validateEmergencyContactPhoneNumber"
              :model="formData.emergency.emergency_contact_phone_number"
              @input="updateEmergencyContactPhoneNumber"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="Address"
              name="emergency_contact_address"
              :validate-input="validateEmergencyContactAddress"
              :model="formData.emergency.emergency_contact_address"
              helptext="(Please note - Maximum 30 characters)"
              @input="updateEmergencyContactAddress"
            />
          </div>
          <div class="c-form__row">
            <text-input
              label="City"
              name="emergency_contact_city"
              :validate-input="validateEmergencyContactCity"
              :model="formData.emergency.emergency_contact_city"
              @input="updateEmergencyContactCity"
            />
          </div>
          <div v-if="isAustralia" class="c-form__row">
            <dropdown
              :options="emergencyContactStateOptions"
              label="State"
              name="emergency_contact_state"
              :validate-input="validateEmergencyContactState"
              :model="formData.emergency.emergency_contact_state"
              @input="updateEmergencyContactState"
            />
          </div>

          <form-group
            label="Bank account"
            :complete="formMeta.bank_account.complete"
            :total="formMeta.bank_account.total"
          />

          <div class="c-form__row">
            <text-input
              label="Bank Account Name"
              name="bank_account_name"
              :validate-input="validateBankAccountName"
              :model="formData.bank_account.bank_account_name"
              @input="updateBankAccountName"
            />
          </div>
          <div v-if="isAustralia" class="c-form__row">
            <bsb-code-input
              label="Bank Account BSB"
              name="bank_account_bsb_number"
              :validate-input="validateBankAccountBsbNumber"
              :model="formData.bank_account.bank_account_bsb_number"
              @input="updateBankAccountBsbNumber"
            />
          </div>
          <div class="c-form__row">
            <masked-input
              label="Bank Account Number"
              name="bank_account_number"
              :mask="isAustralia ? '9{6,9}' : '9{2}-9{4}-9{7}-9{2,3}'"
              :validate-input="validateBankAccountNumber"
              :model="formData.bank_account.bank_account_number"
              @input="updateBankAccountNumber"
            />
          </div>

          <div v-if="!isAustralia" class="c-form__row">
            <dropdown
              :options="taxCodeIdOptions"
              label="Tax Code ID"
              name="tax_code"
              :validate-input="validateTaxCode"
              :model="formData.bank_account.tax_code"
              @input="updateTaxCode"
            />
          </div>
          <div v-if="!isAustralia" class="c-form__row">
            <masked-input
              label="Tax Number"
              name="tax_number"
              mask="9{3}-9{3}-9{3}"
              :validate-input="validateTaxNumber"
              :model="formData.bank_account.tax_number"
              @input="updateTaxNumber"
            />
          </div>

          <form-group
            label="Uniform"
            :complete="formMeta.uniform.complete"
            :total="formMeta.uniform.total"
          />

          <div class="c-form__row">
            <dropdown
              :options="uniformShirtSizeOptions"
              label="Uniform Size - Shirt"
              name="uniform_size_shirt"
              :validate-input="validateUniformSizeShirt"
              :model="formData.uniform.uniform_size_shirt"
              @input="updateUniformSizeShirt"
            />
          </div>
          <div class="c-form__row">
            <dropdown
              :options="uniformPantsSizeOptions"
              label="Uniform Size - Pants"
              name="uniform_size_pants"
              :validate-input="validateUniformSizePants"
              :model="formData.uniform.uniform_size_pants"
              @input="updateUniformSizePants"
            />
          </div>

          <form-group
            label="Other"
            :complete="formMeta.other.complete"
            :total="formMeta.other.total"
          />

          <div class="c-form__row">
            <paragraph>
              <p>
                By clicking accept, you acknowledge you are accepting employment
                with McDonald's on the above terms and conditions.
              </p>
            </paragraph>
          </div>
          <div v-if="!isAustralia" class="c-form__row">
            <text-input
              label="Type in Name"
              name="type_in_name"
              :validate-input="validateTypeInName"
              :model="formData.other.type_in_name"
              @input="updateTypeInName"
            />
          </div>
          <div class="c-form__row">
            <datepicker
              label="Today's date"
              name="form_completion_date"
              :validate-input="validateFormCompletionDate"
              :model="formData.other.form_completion_date"
              @input="updateFormCompletionDate"
            />
          </div>

          <div class="c-form__row c-form__row--buttons">
            <form-button
              button-style="decline"
              name="consent"
              label="DECLINE"
              type="submit"
              onclick="confirm('Are you sure?')"
              @click="declineForm"
            />
            <form-button
              button-style="accept"
              name="consent"
              label="ACCEPT"
              type="submit"
              onclick="confirm('Are you sure?')"
              @click="acceptForm"
            />
          </div>
          <toce-modal
            :ca-maintenance-position="formDefaults.is_ca_maintenance_position"
            @submit="submit()"
          />
        </form>
      </div>

      <div class="c-page__footer"></div>
    </div>
  </div>
</template>

<script>
import 'core-js/es/object/values';
import fieldDefinitions from 'store/personal-details/field-definitions';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import ToceModal from './toce-modal.vue';
import TextInput from '../forms/text-input.vue';
import PhoneInput from '../forms/phone-input.vue';
import MaskedInput from '../forms/masked-input.vue';
import Dropdown from '../forms/dropdown.vue';
import Paragraph from '../forms/paragraph.vue';
import FormButton from '../forms/button.vue';
import Datepicker from '../forms/datepicker.vue';
import FormGroup from '../forms/form-group.vue';
import BsbCodeInput from '../forms/bsb-code-input.vue';
import state from '../../store/personal-details/state';

export default {
  components: {
    TextInput,
    PhoneInput,
    MaskedInput,
    Dropdown,
    Paragraph,
    FormButton,
    Datepicker,
    FormGroup,
    ToceModal,
    BsbCodeInput,
  },
  props: {
    submitEndpoint: {
      type: String,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    formDefaults: {
      type: Object,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    birthDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      salutationOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.salutation.values),
      ],
      genderOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.gender.values),
      ],
      ethnicityOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.ethnicity.values),
      ],
      relationshipOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(
          this.formDefaults.emergency_contact_relationship_values
        ),
      ],
      stateCodeOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.address_state.values),
      ],
      taxCodeIdOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.tax_code.values),
      ],
      uniformShirtSizeOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.uniform_size_shirt.values),
      ],
      uniformPantsSizeOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.uniform_size_pants.values),
      ],
      entitlementToWorkOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.entitlement_to_work.values),
      ],
      passportIssuingCountryOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.passport_issuing_country.values),
      ],
      studentStatusOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.student_status.values),
      ],
      emergencyContactStateOptions: [
        { label: '-- Select --', value: '', disabled: true },
        ...Object.values(this.settings.emergency_contact_state.values),
      ],
    };
  },
  computed: {
    ...mapState(['formData', 'formMeta', 'savingStatus']),
    ...mapGetters([
      'showPassportNumber',
      'showPassportIssuingCountry',
      'showPassportExpirationDate',
      'showBirthCertificateNumber',
      'showVisaExpirationDate',
      'showResidentVisaNumber',
      'showCitizenshipNumber',
    ]),
    wrapperClasses() {
      const classes = ['c-page'];
      if (this.isModalOpen()) {
        classes.push('has-modal');
      }
      if (this.formFinished) {
        classes.push('c-page--centered');
      }
      return classes;
    },
    formFinished() {
      return this.savingStatus === 'success' && this.isValid();
    },
    isAustralia() {
      return this.$store.getters.isAustralia;
    },
    genderOptionsNormalized() {
      if (!this.isAustralia) return this.genderOptions;
      return this.genderOptions.filter(({ value }) => value !== 'O');
    },
  },
  created() {
    const token = document
      .querySelector('meta[name=csrf-token]')
      .getAttribute('content');
    this.$store.commit('setCsrfToken', token);
    this.$store.commit('setCountry', this.country);
    Object.entries(fieldDefinitions).forEach(([label, group]) => {
      /* eslint-disable implicit-arrow-linebreak */
      state.formMeta[label] = {
        total: Object.values(group).filter(
          field =>
            !field.counter_excluded &&
            (!field.country || field.country === this.country)
        ).length,
        complete: 0,
      };
      /* eslint-enable implicit-arrow-linebreak */
    });
    this.$store.commit('updateBirthDate', this.birthDate);
    this.$store.commit('updateFormCompletionDate', this.formatDate(new Date()));
  },
  methods: {
    ...mapMutations([
      'updateSalutation',
      'updateLegalFirstName',
      'updateLegalLastName',
      'updateLegalMiddleName',
      'updatePreferredName',
      'updateBirthDate',
      'updateSchoolType',
      'updateEntitlementToWork',
      'updateResidentVisaNumber',
      'updatePassportNumber',
      'updatePassportIssuingCountry',
      'updatePassportExpirationDate',
      'updateBirthCertificateNumber',
      'updateVisaExpirationDate',
      'updateCitizenshipNumber',
      'updateStudentStatus',
      'updateGender',
      'updateEthnicity',
      'updateAddressStreet1',
      'updateAddressSuburb',
      'updateAddressCity',
      'updateAddressPostcode',
      'updateAddressState',
      'updatePhoneNumber',
      'updateEmergencyContactFirstName',
      'updateEmergencyContactLastName',
      'updateEmergencyContactRelationship',
      'updateEmergencyContactPhoneNumber',
      'updateEmergencyContactAddress',
      'updateEmergencyContactCity',
      'updateEmergencyContactState',
      'updateBankAccountName',
      'updateBankAccountBsbNumber',
      'updateBankAccountNumber',
      'updateTaxCode',
      'updateTaxNumber',
      'updateUniformSizeShirt',
      'updateUniformSizePants',
      'updateTermsOfCasualEmploymentStatus',
      'updateTypeInName',
      'updateFormCompletionDate',
    ]),
    ...mapActions([
      'validateSalutation',
      'validateLegalFirstName',
      'validateLegalLastName',
      'validateLegalMiddleName',
      'validateBirthDate',
      'validateStudentStatus',
      'validateEntitlementToWork',
      'validateResidentVisaNumber',
      'validatePassportNumber',
      'validatePassportIssuingCountry',
      'validatePassportExpirationDate',
      'validateBirthCertificateNumber',
      'validateVisaExpirationDate',
      'validateCitizenshipNumber',
      'validateSchoolType',
      'validateGender',
      'validateEthnicity',
      'validateAddressStreet1',
      'validateAddressSuburb',
      'validateAddressCity',
      'validateAddressPostcode',
      'validateAddressState',
      'validatePhoneNumber',
      'validateEmergencyContactFirstName',
      'validateEmergencyContactLastName',
      'validateEmergencyContactRelationship',
      'validateEmergencyContactPhoneNumber',
      'validateEmergencyContactAddress',
      'validateEmergencyContactCity',
      'validateEmergencyContactState',
      'validateBankAccountName',
      'validateBankAccountBsbNumber',
      'validateBankAccountNumber',
      'validateTaxCode',
      'validateTaxNumber',
      'validateUniformSizeShirt',
      'validateUniformSizePants',
      'validateTermsOfCasualEmploymentStatus',
      'validateTypeInName',
      'validateFormCompletionDate',
      'openModal',
      'closeModal',
    ]),
    ...mapGetters(['isModalOpen', 'isValid']),
    submit() {
      this.closeModal();
      this.$store.dispatch('submit', {
        endpoint: this.submitEndpoint,
        country: this.country,
      });
    },
    submitNewZealand() {
      if (this.isAustralia) {
        return false;
      }

      if (this.isValid()) {
        this.submit();
      }
      return true;
    },
    async acceptForm() {
      this.$store.commit('accept');
      await this.$store.dispatch('validate', this.country);

      if (this.submitNewZealand()) return;

      if (
        this.isValid() &&
        this.formData.other.terms_of_casual_employment_status === null
      ) {
        this.openModal();
        return;
      }

      if (this.formData.other.terms_of_casual_employment_status !== null) {
        this.submit();
      }
    },
    async declineForm() {
      this.$store.commit('decline');
      await this.$store.dispatch('validate', this.country);

      if (this.submitNewZealand()) return;

      if (
        this.isValid() &&
        this.formDefaults.is_casual_offer &&
        this.formDefaults.other.terms_of_casual_employment_status === null
      ) {
        this.openModal();
        return;
      }

      if (
        !this.formDefaults.is_casual_offer ||
        (this.formDefaults.is_casual_offer &&
          this.formData.other.terms_of_casual_employment_status !== null)
      ) {
        this.submit();
      }
    },
    formatDate(date) {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${date.getFullYear()}-${month}-${day}`;
    },
  },
};
</script>
