<template>
  <div class="c-toce__content">
    <h1>McDonald’s Australia Terms of Casual Employment</h1>

    <p>
      In conjunction with the
      <i>Fast Food Industry Award 2010</i> (<strong>Award</strong>) below are
      the terms and conditions of your employment:
    </p>

    <h3>
      Position
    </h3>
    <p>
      You are employed on a casual basis in the position of Crew Member. As a
      casual employee, you will be paid a casual loading of 25% in addition to
      your base rate of pay.
    </p>

    <h3>
      Duties
    </h3>
    <p>You agree to:</p>
    <ol>
      <li>
        comply with all reasonable and lawful directions and instructions given
        by McDonald’s, which may include additional duties in line with your
        skills and competence;
      </li>
      <li>
        act in good faith, use your best endeavours to positively promote
        McDonald’s goodwill and reputation;
      </li>
      <li>
        notify McDonald’s if you are charged and/or convicted by a law
        enforcement agency with a criminal offence, not including charges
        relating to minor traffic infringements not requiring attendance a
        court; and
      </li>
      <li>
        comply with McDonald’s policies as varied from time to time. These
        policies are located on Workplace and are not intended to form part of
        your contract of employment.
      </li>
    </ol>

    <h3>Remuneration</h3>
    <p>
      You can find your rate of pay in the Award which is located on Workplace.
    </p>
    <p>
      As a casual employee, you will be paid a casual loading of 25%. You agree
      that you receive this casual loading as compensation for the fact that you
      are not entitled to the benefits and other attributes of full time or part
      time employment including but not limited to annual leave,
      personal/carer’s leave, notice of termination or redundancy benefits.
    </p>
    <p>
      This clause applies if an industrial instrument (such as the Award,
      another award or an enterprise agreement) applies to your employment and
      requires McDonald’s to provide you with a payment or a benefit (including,
      for example: minimum wage rates and any overtime, weekend and other
      penalty rates, leave entitlements, loadings such as shift loadings, casual
      loading and annual leave loading, and meal or other allowances). In that
      case, you agree that any amount paid to you that is above the minimum wage
      rate in the industrial instrument compensates you for, and can be set-off
      against, any payment or benefit under the industrial instrument.
    </p>
    <p>
      If you are overpaid at any time during your employment with McDonald’s,
      you agree to repay McDonald’s the full amount of any such overpayment. If
      this happens, McDonald’s will discuss repayment options with you.
    </p>

    <h3>Superannuation</h3>
    <p>
      Should you be entitled to superannuation, McDonald’s will make
      superannuation contributions on your behalf as required by the relevant
      superannuation legislation. You can pick your own superannuation fund. If
      you do not pick a superannuation fund, it will be paid into McDonald’s
      nominated fund on your behalf.
    </p>

    <h3>Leave</h3>
    <p>
      In accordance with applicable law you are entitled to the following forms
      of leave: carer’s leave, parental leave, compassionate leave, family and
      domestic violence leave and long service leave. With the exception of long
      service leave, these periods of leave will be unpaid.
    </p>

    <h3>Workplace Safety</h3>
    <p>
      It is a condition of your employment that you comply with your obligations
      under any work, health and safety laws that apply to your employment,
      particularly those that require you to take care of your own health and
      safety and the health and safety of your fellow workers.
    </p>

    <h3>Delivery Drivers</h3>
    <p>
      <u>
        This clause is only applicable to employees who, by mutual agreement
        between McDonald’s and the employee, are engaged to carry out delivery
        driver duties.
      </u>
    </p>
    <p>When performing your duties as a delivery driver, you agree to:</p>
    <ol>
      <li>
        comply with the Delivery Driver Policy, as amended from time to time;
      </li>
      <li>
        comply with all relevant legislation, particularly legislation regarding
        road safety;
      </li>
      <li>
        advise McDonald’s immediately should the status of your licence change
        during your employment due to any cancellation, suspension or
        disqualification notification;
      </li>
      <li>
        personally perform all of the duties assigned to you, and not delegate
        or subcontract to another person; and
      </li>
      <li>
        ensure you are driving responsibly at all times.
      </li>
    </ol>
    <p>
      Where McDonald’s requires you to use your own personal vehicle in the
      performance of your role, you will be entitled to be paid the applicable
      transport allowance specified in the Award.
    </p>

    <h3>Workplace Surveillance</h3>
    <p>
      McDonald’s notifies you that continuous ongoing camera, cash register and
      tracking surveillance is carried out in the restaurant in which you work.
      In areas where camera surveillance occurs, notices reminding you of such
      surveillance are installed. You consent to such surveillance from the date
      you commence employment.
    </p>
    <p>
      If, by mutual agreement between McDonald’s and you, you are engaged to
      carry out deliver driver duties, for insurance reasons McDonald’s is
      required to monitor your movements whilst completing deliveries. This
      surveillance may be carried out using GPS tracking technology by
      installing a mobile telephone application (as specified by McDonald’s)
      onto your personal mobile telephone.
    </p>
    <p>
      You consent to such surveillance from the commencement of your engagement
      as a delivery driver and you agree to take all reasonable steps to ensure
      the tracking app is operating whilst performing work as a delivery driver.
      Additionally, if you carry out your delivery duties using a vehicle
      provided by McDonald’s, the vehicle may contain a <i>“dash cam”</i> or
      other video surveillance equipment.
    </p>

    <h3>Termination of Employment</h3>
    <p>
      Your employment commences at the start of each engagement and terminates
      at the end of each engagement. However, McDonald’s may terminate your
      employment, for any reason, by giving one hour’s notice or payment in lieu
      of one hour’s wages.
    </p>

    <p>
      McDonald’s may terminate your employment at any time, without notice, in
      the event that you engage in serious misconduct or for any reason which
      would entitle McDonald’s to summarily dismiss you at common law.
    </p>

    <h3>Acknowledgement</h3>
    <p>
      By clicking on the "Accept" button below, you are providing "written"
      acceptance of McDonald's Australia's Terms of Casual Employment. Should
      you wish to not accept McDonald's Australia's Terms of Casual Employment,
      please click on the "Decline" button below.
    </p>
  </div>
</template>

<script>
export default {};
</script>
