import fieldDefinitions from './field-definitions';

const state = {
  csrfToken: '',
  country: '',
  savingStatus: null,
  errors: [],
  formMeta: {},
  formData: {
    other: { personal_details_status: null },
  },
};

Object.entries(fieldDefinitions).forEach(([label, group]) => {
  const defaults = {};
  Object.keys(group).forEach(fieldName => {
    defaults[fieldName] = fieldDefinitions[label][fieldName].default;
  });
  state.formData[label] = {
    ...state.formData[label],
    ...defaults,
  };
});

export default state;
