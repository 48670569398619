import api from 'api/parental-consent';
import { validatePresence } from 'utils/validators';
import toCamelCase from 'utils/strings';
import 'core-js/stable/promise';
import fieldDefinitions from './field-definitions';
import { consentTypes } from './mutations';

const actions = {
  validateConsent({ state, commit }) {
    if (
      !validatePresence(state.formData.consent) &&
      consentTypes.indexOf(state.formData.consent) !== -1
    ) {
      commit('formInvalid', 'consent');
      return false;
    }
    commit('clearError', 'consent');
    return true;
  },
  async submit({ state, dispatch }, { endpoint }) {
    const valid = await dispatch('validate');
    if (valid) {
      const result = await api.submit(
        endpoint,
        state.csrfToken,
        state.formData
      );
      dispatch('handleSubmitResult', { result });
    }
  },
  async handleSubmitResult({ commit }, { result }) {
    if (result.result === 'failure') {
      Object.keys(result.errors).forEach(field => commit('formInvalid', field));
    }
    commit('savingComplete', result.result);
  },
};

const validations = [];

// adds validations for defined fields
Object.keys(fieldDefinitions).forEach(fieldName => {
  if (!fieldDefinitions[fieldName].validate) return;

  const validationName = `validate${toCamelCase(fieldName)}`;
  validations.push(validationName);
  actions[validationName] = ({ state, commit }) => {
    if (!fieldDefinitions[fieldName].validate(state.formData[fieldName])) {
      commit('formInvalid', fieldName);
      return false;
    }
    commit('clearError', fieldName);
    return true;
  };
});

actions.validate = async ({ getters, dispatch }) => {
  await Promise.all(
    validations.map(async validationName => {
      await dispatch(validationName);
    })
  );
  await dispatch('validateConsent');

  return getters.isValid;
};

export default actions;
