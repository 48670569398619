import api from 'api/personal-details';
import toCamelCase from 'utils/strings';
import fieldDefinitions from './field-definitions';

const actions = {
  async submit({ state, dispatch }, { endpoint, country = undefined }) {
    const valid = await dispatch('validate', country);
    let formData = {};
    Object.values(state.formData).forEach(section => {
      formData = { ...formData, ...section };
    });
    if (valid) {
      const result = await api.submit(endpoint, state.csrfToken, formData);
      dispatch('handleSubmitResult', { result });
    }
  },
  async handleSubmitResult({ commit }, { result }) {
    if (result.result === 'failure') {
      Object.keys(result.errors).forEach(field => commit('formInvalid', field));
    }
    commit('savingComplete', result.result);
  },
};

// adds validations for defined fields
Object.entries(fieldDefinitions).forEach(([label, group]) => {
  Object.entries(group).forEach(([fieldName, field]) => {
    if (!field.validate) return;

    const validationName = `validate${toCamelCase(fieldName)}`;
    actions[validationName] = ({ state, commit }) => {
      if (
        (!field.show || field.show(state)) &&
        !field.validate(state.formData[label][fieldName], state)
      ) {
        commit('formInvalid', fieldName);
        return false;
      }
      commit('clearError', fieldName);
      return true;
    };
  });
});

actions.validate = async ({ getters, dispatch }, country = undefined) => {
  const validations = [];
  Object.entries(fieldDefinitions).forEach(([label, group]) => {
    Object.keys(group).forEach(fieldName => {
      if (!fieldDefinitions[label][fieldName].validate) return;
      if (
        country &&
        fieldDefinitions[label][fieldName].country &&
        fieldDefinitions[label][fieldName].country !== country
      ) {
        return;
      }

      validations.push(`validate${toCamelCase(fieldName)}`);
    });
  });

  await Promise.all(
    validations.map(async validationName => {
      await dispatch(validationName);
    })
  );

  return getters.isValid;
};

export default actions;
