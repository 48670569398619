export default {
  isValid(state) {
    return state.errors.length === 0;
  },
  isFieldValid(state) {
    return field => state.errors.indexOf(field) === -1;
  },
  savingSuccessful(state) {
    return state.saving_status === 'success';
  },
};
