const EMAIL_REGEX = /^\S+@\S+\.\S+$/;
const PHONE_REGEX_NZ = /^[\d]{7,12}$/;
const PHONE_REGEX_AUS = /^04[\d]{5,8}$/;
const NUMBER_REGEX = /^[\d]+$/;

export const validatePresence = input => input && input.length > 0;

export const validateEmail = (email, required = true) => {
  if (required && !validatePresence(email)) {
    return false;
  }

  if (email.length) {
    return EMAIL_REGEX.test(email);
  }

  return true;
};

export const validatePhone = (phone, required = true, country = 'nz') => {
  if (required && !validatePresence(phone)) {
    return false;
  }

  if (phone.length) {
    const regex = country === 'aus' ? PHONE_REGEX_AUS : PHONE_REGEX_NZ;
    return regex.test(phone);
  }

  return true;
};

export const validateMaxLength = (input, maxLength) =>
  !input || input.length <= maxLength;

export const validateNumber = (
  input,
  length = { min: null, max: null },
  required = true
) => {
  if (required && !validatePresence(input)) {
    return false;
  }

  if (length.min && input.length < length.min) {
    return false;
  }

  if (length.max && input.length > length.max) {
    return false;
  }

  if (input.length) {
    return NUMBER_REGEX.test(input);
  }

  return true;
};

export default {
  validatePresence,
  validateEmail,
  validatePhone,
  validateNumber,
  validateMaxLength,
};
