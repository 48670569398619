/* eslint-disable no-param-reassign */

import toCamelCase from 'utils/strings';
import fieldDefinitions from './field-definitions';

export const consentTypes = {
  granted: 'granted',
  declined: 'declined',
};

const mutations = {
  formInvalid(state, field) {
    if (state.errors.indexOf(field) === -1) {
      state.errors.push(field);
    }
  },
  consentGranted(state) {
    state.formData.consent = consentTypes.granted;
  },
  consentDeclined(state) {
    state.formData.consent = consentTypes.declined;
  },
  clearError(state, field) {
    state.errors = state.errors.filter(value => value !== field);
  },
  setCsrfToken(state, token) {
    state.csrfToken = token;
  },
  savingComplete(state, status) {
    state.savingStatus = status;
  },
};

// adds mutations for defined fields
Object.keys(fieldDefinitions).forEach(fieldName => {
  mutations[`update${toCamelCase(fieldName)}`] = (state, value) => {
    state.formData[fieldName] = value;
  };
});

export default mutations;
