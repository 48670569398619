<script>
import Inputmask from 'inputmask';
import TextInput from './text-input.vue';

export default {
  extends: TextInput,
  props: {
    mask: {
      type: String,
      required: true,
    },
  },
  mounted() {
    Inputmask({
      mask: this.mask,
      autoUnmask: true,
    }).mask(this.$refs.inputField);
  },
};
</script>
