import Vue from './utils/vue';
import store from './store/personal-details';
import PersonalDetailsForm from './components/personal-details/index.vue';

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('.app-personal-details-form');

  if (!el) return;

  /* eslint-disable-next-line no-new */
  new Vue({
    el,
    store,
    render(h) {
      return h(PersonalDetailsForm, {
        props: {
          submitEndpoint: el.dataset.endpoint,
          settings: JSON.parse(window.maccas.settings),
          country: el.dataset.country,
          birthDate: el.dataset.birthDate,
          formDefaults: JSON.parse(el.dataset.formDefaults),
        },
      });
    },
  });
});
