<template>
  <div class="c-form-group">
    <span class="c-form-group__label">{{ label }}</span>
    <span class="c-form-group__counter">
      <span>(</span>
      <span>{{ complete }}</span>
      <span>/</span>
      <span>{{ total }}</span>
      <span class="c-form-group__complete">complete</span>
      <span>)</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    complete: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
};
</script>
