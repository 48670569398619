<template>
  <div class="c-modal">
    <div class="c-modal__controls">
      <a
        class="c-modal__close"
        title="Close this window"
        @click="closeModal"
      ></a>
    </div>
    <div class="c-modal__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['closeModal']),
  },
};
</script>
